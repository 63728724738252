<!-- =========================================================================================
    File Name: DatetimePickerInput.vue
    Description: allow input in datetime picker
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="vx-row">
    <div class="vx-col md:w-1/2 w-full">
      <flat-pickr
        :config="configFromdateTimePicker"
        v-model="fromDate"
        placeholder="From Date"
        @on-change="onFromChange"
      />
    </div>
    <div class="vx-col md:w-1/2 w-full">
      <flat-pickr
        :config="configTodateTimePicker"
        v-model="toDate"
        placeholder="To Date"
        @on-change="onToChange"
      />
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  data() {
    return {
      fromDate: null,
      toDate: null,
      configTodateTimePicker: {
        maxDate: new Date(),
        minDate: null,
      },
      configFromdateTimePicker: {
        maxDate: new Date(),
      },
    };
  },
  methods: {
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
  },
  components: {
    flatPickr,
  },
};
</script>
