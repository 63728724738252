<template>
  <div>
    <div>
      <span
        class="text-center table-edit-icon"
        @click="showModal"
        v-if="
          params.data.hasOwnProperty('purchase_image') &&
            params.data.purchase_image != '-' &&
            hideIcon == false
        "
      >
        <i class="text-center material-icons">info</i>
      </span>
      <span class="text-center">No Images</span>
    </div>
    <!-- POPUP -->
    <div class>
      <vs-popup
        style="z-index: 99999999 !important"
        :id="'popmodal' + params.data.purchase_image"
        title="Expense"
        :active.sync="popupActive"
      >
        <!-- Swiper -->
        <swiper :options="swiperOption">
          <swiper-slide
            class="swiper-slide"
            :key="imageKey"
            v-for="(image, imageKey) in images"
          >
            <div class="container">
              <div class="row">
                <div class="col text-center">
                  <h1>
                    <b-badge
                      v-if="ignoredImages.includes(image)"
                      variant="danger"
                      >Ignored</b-badge
                    >
                  </h1>
                </div>
              </div>
            </div>

            <div
              class="swiper-zoom-container"
              v-if="extensions.indexOf(image.split('.').slice(-1)[0]) >= 0"
            >
              <img
                style="height: 60vh; width: auto"
                :src="image + '?token=' + tempToken"
                alt="banner"
              />
            </div>
            <div class="row col-12" v-else>
              <vs-button
                class="px-4 text-center"
                @click.native="downloadPdf(image, tempToken)"
                style="margin: auto"
              >
                <span class="d-inline-flex pr-5 text-white"
                  >Click here to preview</span
                >

                <img
                  src="@/assets/images/pdf-icon.png"
                  v-if="image.split('.').slice(-1)[0] == 'pdf'"
                  class="d-inline-flex"
                  height="40"
                  width="40"
                />
                <img
                  src="@/assets/images/docx_icon.png"
                  v-else-if="
                    image.split('.').slice(-1)[0] == 'docx' ||
                      image.split('.').slice(-1)[0] == 'doc'
                  "
                  class="d-inline-flex"
                  height="40"
                  width="40"
                />
                <img
                  src="@/assets/images/excelLogo.png"
                  v-else
                  class="d-inline-flex"
                  height="40"
                  width="40"
                />
              </vs-button>
            </div>
            <div
              class="mx-auto text-center my-2"
              v-if="extensions.indexOf(image.split('.').slice(-1)[0]) >= 0"
            >
              <button
                @click="downloadPdf(image)"
                class="vs-component vs-button vs-button-primary vs-button-filled"
              >
                Preview
              </button>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { BASEURL } from "@/config/index.js";

export default Vue.extend({
  props: {
    imagePopParamsData: Object,
  },
  beforeMount() {
    if (this.imagePopParamsData) {
      this.params = this.imagePopParamsData;
      this.hideIcon = true;
      this.showModal();
    }
  },
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      UPLOAD_URL: BASEURL + "/uploads/",
      tempToken: window.localStorage.getItem("TempToken"),
      images: [],
      ignoredImages: [],
      popupActive: false,
      hideIcon: false,
      extensions: ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG", "webp", "WEBP"],
      params: null,
      value: null,
      count: 0,
      swiperOption: {
        slidesPerView: 1,
        autoHeight: true,
        spaceBetween: 30,
        zoom: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  watch: {},
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodal" + this.params.data.purchase_image
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    downloadPdf(img, tempToken) {
      console.log(
        this.UPLOAD_URL +
          img +
          "?token=" +
          window.localStorage.getItem("TempToken")
      );
      window.open(img + "?token=" + window.localStorage.getItem("TempToken"));
    },
    showModal() {
      this.popupActive = true;
      let obj = {
        budget_id: this.params.data.budget_id,
        show: false,
        budget_code: this.params.data.budget_code,
      };
      eventBus.$emit(
        "showPopupExpense" + this.params.data.budget_code + "Code",
        obj
      );
      eventBus.$emit(
        "showPopupExpense" + this.params.data.budget_id + "Category",
        obj
      );
    },
    onClickCloseButton() {
      let obj = {
        budget_id: this.params.data.budget_id,
        show: true,
        budget_code: this.params.data.budget_code,
      };
      console.log(obj);
      eventBus.$emit(
        "showPopupExpense" + this.params.data.budget_code + "Code",
        obj
      );
      eventBus.$emit(
        "showPopupExpense" + this.params.data.budget_id + "Category",
        obj
      );
    },
    submitForm() {},
  },

  mounted() {
    this.images =
      this.params.value.length > 1
        ? this.params.value.split(",").filter((x) => {
            return x != "-";
          })
        : [];

    this.ignoredImages = this.params.data.ignored_image
      ? this.params.data.ignored_image.split(",").filter((x) => {
          return x != "-";
        })
      : [];

    if (this.ignoredImages.length > 0) {
      this.images = this.images.concat(this.ignoredImages);
    }

    const next = this.$children[0].$refs.btnclose;
    next.$el.addEventListener("click", this.onClickCloseButton, false);
  },
});
</script>
<style>
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  overflow: hidden;
}
</style>
