import Api from './Api.js';

const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  getAllSerchResult(val, result, payload, archive = false) {
    let url = `/v2/${result}`
    if (payload != null) {
      Object.keys(payload).map(x => {
        url += "/" + payload[x];
        console.log('payload[x] payload[x]:', payload[x], x);
      });
    }


    url += `?search=${val}&organization_id=${organization_id()}&isDashboard=1`
    if (archive) {
      url += "&archive=1"
    }
    return Api().get(url);
  },
  getAllSerchResultV2(val, result, payload, archive = false) {
    let url = `/v2/${result}`
    url += `?search=${val}&organization_id=${organization_id()}`
    if (payload) {
      Object.keys(payload).map(x => {
        url += `&${x}=` + payload[x];
      });
    }
    if (archive) {
      url += "&archive=1"
    }
    if (result == 'users') {
      url += `&contract_active=1&page_no=1`
    } else if (result == 'contractVendor') {
      url += `&from=dashboard`
    }
    return Api().get(url);
  }
}
