import Api from "./Api.js";
// let organization_id = window.localStorage.getItem("OrganizationID");

const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  getVARList(filters) {
    return Api().get(
      `/v2/vendorAdvanceRequest/projectVarList?organization_id=${organization_id()}&page_no=${
        filters.page
      }&project=${filters.selectedProjectId}&vendor=${
        filters.selectedVendorId
      }`,
      filters
    );
  },
  getPCRList(filters) {
    return Api().get(
      `/v2/vendorAdvanceRequest/projectPcrList?organization_id=${organization_id()}&page_no=${
        filters.page
      }&project=${filters.selectedProjectId}&vendor=${
        filters.selectedVendorId
      }`,
      filters
    );
  },

  addReturnAmount(payload) {
    return Api().post(
      `/v2/vendorAdvanceRequest/addReturnAmount?organization_id=${organization_id()}`,
      payload
    );
  },

  getVARListByVendor(filters) {
    return Api().get(
      `/v2/vendorAdvanceRequest/projectVarListByVendor?organization_id=${organization_id()}&page_no=${
        filters.page
      }&vendor_id=${filters.vendor_id}&project_id=${
        filters.project_id
      }&modal_id=${filters.modal_id}`,
      filters
    );
  },

  getPCRListByVendor(filters) {
    return Api().get(
      `/v2/vendorAdvanceRequest/projectPcrListByVendor?organization_id=${organization_id()}&page_no=${
        filters.page
      }&vendor_id=${filters.user_id}&project_id=${
        filters.project_id
      }&modal_id=${filters.modal_id}&vendor_id_main=${
        filters.actual_vendor_id
      }`,
      filters
    );
  },

  getPCRListByUser(filters) {
    return Api().get(
      `/v2/vendorAdvanceRequest/projectPCRLogs?organization_id=${organization_id()}&page_no=${
        filters.page
      }&user_id=${filters.user_id}&project_id=${filters.project_id}&modal_id=${
        filters.modal_id
      }`,
      filters
    );
  },

  verifyExpense(payload) {
    return Api().post(
      `/v2/purchase/verifyExpense?organization_id=${organization_id()}`,
      payload
    );
  },
};
