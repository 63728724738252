import Api from './Api.js';
// let organization_id = window.localStorage.getItem("OrganizationID");


const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addCashRequest(payload, id) {
    return Api().post(`/v2/cashmang?organization_id=${organization_id()}`, payload);
  },
  getAllPurchase(payload) {
    return Api().post(`/v2/cashmang/getVendorExpenses?organization_id=${organization_id()}`, payload);
  },
   updateCashRequest(payload) {
     return Api().put(`/v2/cashmang/${payload.advance_id}/${payload.allocated}?organization_id=${organization_id()}`, payload);
   },
     getRequestList(filters) {
       let url = `/v2/cashmang/${filters.allocated}?organization_id=${organization_id()}&page_no=${filters.page}`;
      if (filters.hasOwnProperty("search")) {
        url += `&search=${filters.search}`;
      }
      return Api().get(url);

    },
     getRequestListGroupUser(filters) {
       let url = `/v2/cashmang/byuser/1?organization_id=${organization_id()}`;
      if (filters.hasOwnProperty("search")) {
        url += `&search=${filters.search}`;
      }
      return Api().get(url);
    },
    getCashRequestByUser(filters) {
      let url = `/v2/cashmang/${filters.user_id}/1?organization_id=${organization_id()}`;
     if (filters.hasOwnProperty("search")) {
       url += `&search=${filters.search}`;
     }
     return Api().get(url);
   },
   getCashRequestPurchaseByUser(filters) {
     let url = `/v2/cashmang/purchase/${filters.user_id}/1?organization_id=${organization_id()}`;
    if (filters.hasOwnProperty("search")) {
      url += `&search=${filters.search}`;
    }
    return Api().get(url);
  }
};
