<template>
  <div>
    <span @click="getPreview()">
      <div style="width: 100%">{{ params.data.totalExpenseAmount }}</div>
    </span>
    <div class="demo-alignment">
      <vs-popup
        fullscreen
        :id="'popmodalView' + params.data.budget_id"
        class="holamundo p-2"
        title="Expense Detail"
        :active.sync="popupActive"
      >
        <div class="row mx-0 text-center mt-5 mb-base">
          <div class="col-2">
            <h6>User Name:</h6>
            <h3 class="text-primary">{{ params.data.user_name }}</h3>
          </div>

          <!-- <div class="col-1">
            <h6>Project:</h6>
            <h3 class="text-primary">{{ expenseDetail.expense_code }}</h3>
          </div> -->

          <div class="col-2">
            <h6>Expense Amount:</h6>
            <h3 class="text-primary">{{ this.expenseTotal }}</h3>
          </div>
        </div>

        <div>
          <div class="col-12 row">
            <div class="col-10"></div>
            <!-- <div class="col-2">
              <vs-input
                class="mb-4 md:mb-0 mr-4 ml-3 "
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
            </div> -->
          </div>
          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowClicked="onRowDataChanged"
            :rowData="budgetLogs"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="false"
          ></ag-grid-vue>
          <!-- :paginationPageSize="paginationPageSize"
            :pagination="true"
          :suppressPaginationPanel="true"-->
          <!-- <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" /> -->
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { AgGridVue } from "ag-grid-vue";
import CashManagementService from "@/services/CashManagementService";
export default Vue.extend({
  components: {
    AgGridVue,
  },
  data() {
    return {
      params: null,

      popupActive: false,
      gridApi: null,
      searchQuery: "",

      gridOptions: {},
      maxPageNumbers: 7,
      budgetLogs: [],
      expenseTotal: 0,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [],
      //   project_id: ""
    };
  },

  beforeMount: function () {
    this.advance_id = this.params.value;
    this.columnDefs = [
      {
        headerName: "Expense Name",
        field: "po_name",
        filter: false,
      },
      {
        headerName: "Invoice No",
        field: "invoice_number",
        filter: false,
      },
      {
        headerName: "Amount",
        field: "formattedAmount",
        filter: false,
      },
      {
        headerName: "Date",
        field: "created_date",
        filter: false,
      },
    ];
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalView" + this.params.data.advance_id
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    getPreview: function () {
      this.popupActive = true;
      let payload = {
        purchase_id: this.params.data.purchase_id,
        user_id: this.params.data.user_id,
      };
      CashManagementService.getCashRequestPurchaseByUser(payload)
        .then((response) => {
          const { data } = response;
          if (!data.err || data.Status == true) {
            this.budgetLogs = data.data;
            this.expenseTotal = data.expenseTotal;
          } else {
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
  },
});
</script>

<style>
</style>
