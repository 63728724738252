var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(
        _vm.params.data.hasOwnProperty('purchase_image') &&
          _vm.params.data.purchase_image != '-' &&
          _vm.hideIcon == false
      )?_c('span',{staticClass:"text-center table-edit-icon",on:{"click":_vm.showModal}},[_c('i',{staticClass:"text-center material-icons"},[_vm._v("info")])]):_vm._e(),_c('span',{staticClass:"text-center"},[_vm._v("No Images")])]),_c('div',{},[_c('vs-popup',{staticStyle:{"z-index":"99999999 !important"},attrs:{"id":'popmodal' + _vm.params.data.purchase_image,"title":"Expense","active":_vm.popupActive},on:{"update:active":function($event){_vm.popupActive=$event}}},[_c('swiper',{attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.images),function(image,imageKey){return _c('swiper-slide',{key:imageKey,staticClass:"swiper-slide"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('h1',[(_vm.ignoredImages.includes(image))?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Ignored")]):_vm._e()],1)])])]),(_vm.extensions.indexOf(image.split('.').slice(-1)[0]) >= 0)?_c('div',{staticClass:"swiper-zoom-container"},[_c('img',{staticStyle:{"height":"60vh","width":"auto"},attrs:{"src":image + '?token=' + _vm.tempToken,"alt":"banner"}})]):_c('div',{staticClass:"row col-12"},[_c('vs-button',{staticClass:"px-4 text-center",staticStyle:{"margin":"auto"},nativeOn:{"click":function($event){return _vm.downloadPdf(image, _vm.tempToken)}}},[_c('span',{staticClass:"d-inline-flex pr-5 text-white"},[_vm._v("Click here to preview")]),(image.split('.').slice(-1)[0] == 'pdf')?_c('img',{staticClass:"d-inline-flex",attrs:{"src":require("@/assets/images/pdf-icon.png"),"height":"40","width":"40"}}):(
                  image.split('.').slice(-1)[0] == 'docx' ||
                    image.split('.').slice(-1)[0] == 'doc'
                )?_c('img',{staticClass:"d-inline-flex",attrs:{"src":require("@/assets/images/docx_icon.png"),"height":"40","width":"40"}}):_c('img',{staticClass:"d-inline-flex",attrs:{"src":require("@/assets/images/excelLogo.png"),"height":"40","width":"40"}})])],1),(_vm.extensions.indexOf(image.split('.').slice(-1)[0]) >= 0)?_c('div',{staticClass:"mx-auto text-center my-2"},[_c('button',{staticClass:"vs-component vs-button vs-button-primary vs-button-filled",on:{"click":function($event){return _vm.downloadPdf(image)}}},[_vm._v("\n              Preview\n            ")])]):_vm._e()])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }