import Api from "./Api.js";
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addProject(payload) {
    return Api().post(
      `/v2/project?organization_id=${organization_id()}`,
      payload
    );
  },
  replaceUser(payload) {
    return Api().post(
      `/v2/projectmap/replaceUser?organization_id=${organization_id()}`,
      payload
    );
  },
  addProjectLevels(payload) {
    return Api().post(
      `/v2/projectmap?organization_id=${organization_id()}`,
      payload
    );
  },
  editProjectLevels(payload, id) {
    return Api().put(
      `/v2/projectmap/${id}?organization_id=${organization_id()}`,
      payload
    );
  },
  editProject(payload) {
    return Api().put(
      `/v2/project/${payload.project_id}?organization_id=${organization_id()}`,
      payload
    );
  },
  deleteProject(project_id) {
    return Api().delete(
      `/v2/project/${project_id}?organization_id=${organization_id()}`
    );
  },

  getAllProjects(filters, user) {
    if (filters && filters.hasOwnProperty("archive") && filters.archive) {
      return Api().get(
        `/v2/project?archive=1&organization_id=${organization_id()}&isDashboard=1&user=` +
        user
      );
    } else
      return Api().get(
        `/v2/project?organization_id=${organization_id()}&isDashboard=1&user=` +
        user
      );
  },

  getAllProjectsByUserRole(filters, sort) {
    if (filters && filters.hasOwnProperty("archive") && filters.archive) {
      return Api().get(
        `/v2/project?archive=1&isDashboard=1&organization_id=${organization_id()}`
      );
    } else
      return Api().get(
        `/v2/project?isDashboard=1&organization_id=${organization_id()}`
      );
  },

  getAllBudgetProjects() {
    return Api().get(
      `/v2/project?project_budgetactive=1&organization_id=${organization_id()}`
    );
  },

  getPOPdf(id) {
    return Api().get(
      `/v2/purchase/download/${id}?organization_id=${organization_id()}`
    );
  },
  getProject(id) {
    return Api().get(
      `/v2/project/${id}?organization_id=${organization_id()}&archieve=1&isDashboard=1`
    );
  },
  getProjectLevels(id) {
    return Api().get(
      `/v2/projectmap/project/${id}?organization_id=${organization_id()}`
    );
  },

  getProjectLevels(id) {
    return Api().get(
      `/v2/projectmap/project/${id}?organization_id=${organization_id()}`
    );
  },

  getAdminManagerUsers(id) {
    return Api().get(
      `/v2/project/adminManagerUsers/${id}?organization_id=${organization_id()}`
    );
  },

  getModeratorUsers(id) {
    return Api().get(
      `/v2/project/moderatorUsers/${id}?organization_id=${organization_id()}`
    );
  },

  getDCRHead(id) {
    return Api().get(`/v2/dcrhead/${id}?organization_id=${organization_id()}`);
  },
  addDCRHead(project_id, payload) {
    // return Api().get(`/v2/dcrhead/${id}?organization_id=${organization_id()}`);

    return Api().post(
      `/v2/dcrhead/${project_id}?organization_id=${organization_id()}`,
      payload
    );
  },
  updateDCRHeadValue(project_id, payload) {
    // return Api().get(`/v2/dcrhead/${id}?organization_id=${organization_id()}`);

    return Api().put(
      `/v2/dcrhead/${project_id}?organization_id=${organization_id()}`,
      payload
    );
  },
  getDataByUserAndProject(project_id, user_id) {
    return Api().get(`/v2/projectmap/user/${project_id}/${user_id}`);
  },
};
