<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <!-- DEPARTMENTS LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <!-- <h4 class>Test</h4> -->
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
              v-if="false"
            >
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <!-- <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
              >Export as CSV</vs-button>
              </div>-->
              <vx-tooltip text="Export Excel">
                <!-- :href="excelurl+`/v2/department/excel/download?${}&search=${searchQuery}`" -->
                <a
                  :href="
                    excelurl +
                      `/v2/department/excel/download?organization_id=${organization_id}&token=${temp_token}&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive != 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>

              <vx-tooltip text="Export Archive Excel">
                <a
                  :href="
                    excelurl +
                      `/v2/department/excel/download?organization_id=${organization_id}&token=${temp_token}&archive=1&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive == 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>

                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 col-sm-6 mb-3">
            <h6>Project</h6>
            <multiselect
              v-model="projects_option"
              track-by="project_id"
              label="project_name"
              @input="({ project_id }) => (this.selectedProjectId = project_id)"
              :options="projects"
              name="Project Name"
              placeholder="Select Project"
              :searchable="true"
              :allow-empty="false"
              open-direction="bottom"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.project_name }}
              </template>
            </multiselect>
            <p class="error-msg">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Project Name')"
                >{{ errors.first("Project Name") }}</span
              >
            </p>
          </div>
          <div class="col-12 col-md-4 col-sm-6 mb-3">
            <h6>Impressed User</h6>
            <multiselect
              :disabled="selectedProjectId ? false : true"
              v-model="vendor_options"
              track-by="vendor_id"
              label="vendor_name"
              @input="({ vendor_id }) => (this.selectedVendorId = vendor_id)"
              :options="vendors"
              name="Vendor Name"
              placeholder="Select Impressed User"
              :searchable="true"
              :allow-empty="false"
              open-direction="bottom"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.vendor_name }}
              </template>
            </multiselect>
            <p class="error-msg">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Vendor Name')"
                >{{ errors.first("Vendor Name") }}</span
              >
            </p>
          </div>
          <div class="col-12 col-md-4 mb-3 mt-6">
            <vs-button
              :disabled="!selectedProjectId && !selectedVendorId ? true : false"
              class="md:mb-0 text-hover-white mb-3 w-full sm:w-auto"
              @click="getVARList()"
              >Search</vs-button
            >
            <vs-button
              color="warning"
              type="border"
              class="mb-3 ml-0 sm:ml-3 w-full sm:w-auto"
              @click.prevent="clearForm()"
              >Reset</vs-button
            >
            <vs-button
              v-if="departments.length > 0"
              class="px-4 ml-3"
              @click.native="downloadExcel"
            >
              <span class="d-inline-flex pr-5 text-white">Export</span>
              <img
                src="@/assets/images/icons/export-excel.svg"
                class="d-inline-flex"
                height="20"
                width="20"
              />
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          @cell-clicked="onCellClicked"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowClicked="onRowDataChanged"
          :rowData="departments"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :paginationPageSize="paginationPageSize"
          :pagination="true"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
    <div v-if="showModal == true">
      <pcrlogs-popup :paramsData="paramsData"></pcrlogs-popup>
    </div>
    <div v-if="showModalPCRAproved == true">
      <popupPcr :paramsData="paramsDataPCRPopup"> </popupPcr>
    </div>
    <!-- <div>
      <project-pcr-list-pop-up></project-pcr-list-pop-up>
    </div> -->
    <vs-popup
      class="popmodalReturn"
      style="z-index: 999999 !important"
      :id="'popmodal'"
      title="Return Amount"
      :active.sync="popupActiveReturn"
    >
      <vx-card>
        <h6>Return <em></em></h6>
        <vs-input
          type="number"
          class="mb-4 md:mb-0 mr-4"
          v-model="return_amount"
        />
        <h6 class="mt-2">Description <em></em></h6>
        <vs-textarea
          type="text"
          class="mb-4 md:mb-0 mr-4"
          v-model="description"
        />

        <vs-button
          :disabled="!return_amount || return_amount == 0 ? true : false"
          class="mb-4 mt-4 md:mb-0 text-hover-white"
          @click="addReturnAmount()"
          >Submit</vs-button
        >
      </vx-card>
    </vs-popup>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import * as XLSX from "xlsx";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
const VxTour = () => import("@/components/VxTour.vue");
import VendorAdvanceRequestService from "@/services/VendorAdvanceRequestService.js";
import pcrlogsPopup from "@/views/apps/vendorAdvanceRequest/pcrlogsPopup.vue";
import popupPcr from "@/views/apps/vendorAdvanceRequest/projectPCRListPopUp.vue";
import SearchService from "@/services/searchService.js";
import projectService from "@/services/projectService.js";
import ContractService from "@/services/ContractService.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
// import departmentDetails from "./departmentDetails.vue";

import { BASEURL } from "@/config/index.js";

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import Popup from "../../components/vuesax/popup/Popup.vue";
//import PcrlogsPopup from "../vendorAdvanceRequest/pcrlogsPopup.vue";

export default {
  components: {
    AgGridVue,
    VxTour,
    pcrlogsPopup,
    popupPcr,

    DatetimePickerStartEnd,
    "switch-component": {
      template: `<span>KOZO</span>`,
    },
    Popup,
    // ProjectPCRListPopUp,
    //PcrlogsPopup,
    // projectPCRListPopUp,
  },
  props: ["listType"],
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),
      PermissionVariables: PermissionVariables,
      selectedVendorId: null,
      selectedProjectId: null,
      projects_option: [],
      projects: [],
      vendors: [],
      vendor_options: [],
      excelurl: BASEURL,
      showModal: false,
      showModalExpApproved: false,
      showModalPCRAproved: false,
      popupActiveReturn: false,
      return_amount: 0,
      description: "",
      paramsData: null,
      paramsDataPCRPopup: null,
      impressedUserID: null,
      impressedVendor: null,
      projectID: null,
      organization_id: "",
      totalPages: 0,
      overlayLoadingTemplate: null,
      archive: this.listType,
      departments: [],
      User: "Select User",
      switchValue: true,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
      columnDefs: [],
    };
  },
  beforeMount() {
    //this.getVARList(this.currentPage);

    this.getProjectsList();
    this.getVendorsList();

    this.organization_id = localStorage.getItem("OrganizationID");
    this.columnDefs = [
      {
        headerName: "Project Name",
        field: "project_name",
        filter: false,
        width: 255,
      },
      // {
      //   headerName: "Category",
      //   field: "category_name",
      //   filter: false,
      //   width: 255,
      // },
      {
        headerName: "Immpressed User Name",
        field: "po_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRenderer: function(params) {
          return `<span class="font-bold" style="color:red">${
            params.data.po_name
          }<div>`;
        },
      },
      {
        headerName: "Created By ",
        field: "user_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
        cellRenderer: function(params) {
          return `<span class="font-bold" style="color:red">${
            params.data.user_name
          }<div>`;
        },
      },

      {
        headerName: "Approved PCR Amount",
        field: "totalVARAmount",
        filter: false,
        cellStyle: {
          "text-align": "center",
          background: "green",
          color: "white",
        },
        cellRenderer: function(params) {
          //  class="font-bold" style="color:black"
          return `<span >${params.data.totalVARAmount.toFixed(2)}<div>`;
        },
        width: 255,
      },
      {
        headerName: "Approved Expense Created From PCR Amount",
        field: "totalExpenseCreatedFromVARAmount",
        filter: false,
        width: 255,
        cellStyle: {
          "text-align": "center",
          background: "green",
          color: "white",
        },
        cellRenderer: function(params) {
          return `<span >${params.data.totalExpenseCreatedFromVARAmount.toFixed(
            2
          )}<div>`;
        },
      },
      {
        headerName: "Balance",
        field: "balance",
        filter: false,
        width: 255,
        cellRenderer: function(params) {
          return `<span >${params.data.balance.toFixed(2)}<div>`;
        },
      },

      {
        headerName: "Return Amount ",
        field: "totalReturnAmount",
        filter: false,

        cellStyle: { "text-align": "center", background: "red" },
        width: 255,
        cellRenderer: function(params) {
          return `<span >${
            params.data.totalReturnAmount
              ? params.data.totalReturnAmount.toFixed(2)
              : 0
          }<div>`;
        },
      },
      {
        headerName: "Final Balance Amount ",
        field: "finalBalance",
        filter: false,
        width: 255,
        cellRenderer: function(params) {
          return `<span >${
            params.data.finalBalance ? params.data.finalBalance.toFixed(2) : 0
          }<div>`;
        },
      },
      {
        headerName: "Add Return Amount",
        field: "return_amount",
        filter: false,
        width: 175,
        // pinned: "right",
        sortable: false,
        hide:
          !this.permissionPageAccessArry.includes(
            PermissionVariables.AddReturnAmount
          ) && this.loginUserRole != "Admin"
            ? true
            : false,
        cellStyle: { "text-align": "center" },
        cellRenderer: function(params) {
          return params.data.balance > 0
            ? ' <span class="text-center table-edit-icon" >  <i class="text-center material-icons">edit</i> </span>'
            : "NA";
        },
        // cellRendererFramework: editPoOrder,
        // cellRenderer: function(params) {
        //   return '<span class="text-center cursor-pointer" @click="popupActive=true" ><i class="text-center material-icons">edit</i></span>';
        // }
      },
    ];
    // this.setColums();
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          //this.getVARList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    downloadExcel: function() {
      let data = [];
      for (var i = 0; i < this.departments.length; i++) {
        var odata = this.departments[i];
        var obj = {};
        obj["Project Name"] = odata.project_name;
        obj["Immpressed User Name"] = odata.user_name;
        obj["Approved PCR Amount"] = odata.totalVARAmount;
        obj["Approved Expense Created From PCR Amount"] =
          odata.totalExpenseCreatedFromVARAmount;
        obj["Balance"] = odata.balance;
        obj["Return Amount"] = odata.totalReturnAmount;
        obj["Final Balance Amount"] = odata.finalBalance;
        data.push(obj);
      }
      var dataOne = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataOne, "TRANSACTIONS"); // sheetAName is name of Worksheet
      XLSX.writeFile(wb, `PCRList.xlsx`);
    },
    getProjectsList: function() {
      this.projects = [];
      projectService
        .getAllProjects(null, 1)
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map((x) => {
                if (
                  this.permissionPageAccessArry.includes("40P" + x.project_id)
                ) {
                  console.log(x, "sasa");
                  this.projects.push(x);
                  //this.projects.push(x);
                }
              });
            } else {
              this.projects = data.data;
            }

            console.log(this.projects, "hg");
            //this.projects = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVendorsList: function() {
      this.vendors = [];
      this.pancardList = [];
      let filter = {
        is_impressed: 2,
      };
      ContractService.getContractVendorNew(filter)
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.vendors = data.data;

            console.log(this.vendors, "hhh");

            data.data.map((x) => {
              if (x.vendor_pan != null) {
                this.pancardList.push(x);
              }
            });

            data.vhc_data.map((x) => {
              if (x.is_var == "2") {
                this.VHCList.push(x);
              }
            });

            console.log(data.vhc_data);
            //this.VHCList = data.vhc_data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addReturnAmount() {
      let payload = {
        projectID: this.projectID,
        impressedUserID: this.impressedUserID,
        impressedVendor: this.impressedVendor,
        return_amount: this.return_amount,
        description: this.description,
      };
      VendorAdvanceRequestService.addReturnAmount(payload)
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.getVARList();
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            // this.gridApi.sizeColumnsToFit();
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
      this.popupActiveReturn = false;
      this.return_amount = "";
      this.description = "";
    },
    onCellClicked(params) {
      console.log(params);
      this.projectID = params.data.project_id;
      this.impressedUserID = params.data.user_id;
      this.impressedVendor = params.data.vendor_id;
      this.showModal = false;
      this.showModalPCRAproved = false;
      if (
        params.colDef.field == "return_amount" &&
        params.data.finalBalance > 0
      ) {
        // params.data["modalID"] = "VAR";
        this.paramsData = params;
        setTimeout(() => {
          this.popupActiveReturn = true;
        }, 300);
      }

      if (params.colDef.field == "totalReturnAmount") {
        this.paramsData = params;
        setTimeout(() => {
          this.showModal = true;
        }, 300);
      }
      if (params.colDef.field == "totalVARAmount") {
        params.data["modalID"] = "PCR";
        this.paramsDataPCRPopup = params;
        setTimeout(() => {
          this.showModalPCRAproved = true;
        }, 100);
      }
      if (params.colDef.field == "totalExpenseCreatedFromVARAmount") {
        params.data["modalID"] = "EXP";
        this.paramsDataPCRPopup = params;
        setTimeout(() => {
          this.showModalPCRAproved = true;
        }, 300);
      }

      // if (params.colDef.field == "totalExpenseCreatedFromVARAmount") {
      //   params.data["modalID"] = "EXP";
      //   this.paramsData = params;
      //   setTimeout(() => {
      //     this.showModal = true;
      //   }, 300);
      // }
    },
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function() {
      console.log("object");
    },
    updateSearchQuery(val) {
      SearchService.getAllSerchResult(val, "department", null)
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.departments = data.data;
          } else {
            this.departments = [];
            // this.$vs.notify({
            //   title: "Search data not found",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning",
            // });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    clearForm: function() {
      this.projects_option = [];

      this.vendor_options = [];
      this.selectedVendorId = null;
      this.selectedProjectId = null;
      this.departments = [];
      // this.getVARList();
    },
    getVARList: function(currentPage) {
      this.departments = [];
      let filter = {
        page: currentPage,
        allocated: 2,
        selectedVendorId: this.selectedVendorId,
        selectedProjectId: this.selectedProjectId,
      };
      VendorAdvanceRequestService.getPCRList(filter)
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
            this.$vs.notify({
              title: "Data Not Found",
              text: error.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          } else {
            this.departments = data.data;
            this.totalPages = data.pagination.last_page;
            // this.gridApi.sizeColumnsToFit();
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
  },
  mounted() {
    // if (this.checkPermission(["department", "GET"])) {
    // this.getCashRequestList(this.currentPage);
    // }
    this.gridApi = this.gridOptions.api;
  },
};
</script>
